import * as Types from "@coral/typings";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export const CampaignExpandedFragmentDoc = gql`
  fragment CampaignExpanded on Campaign {
    id
    value
    name
    hardBoostedSkus
    createdBy {
      id
    }
    filterAttributes {
      id
      fieldName
      rule
      numValue
      strValue
      strArrValue
    }
    rankAttributes {
      id
      fieldName
      weight
      rule
      numValue
      strValue
      strArrValue
    }
    type
    modifiedBy
    createdAt
    updatedAt
  }
`;
export const CustomFeedExpandedFragmentDoc = gql`
  fragment CustomFeedExpanded on CustomFeed {
    id
    landing_page
    name
    feed_type
    product_tile_html_template
    project_id
    state
    recipe {
      feed {
        name
        params {
          name
        }
      }
      filter {
        dynamic {
          rule_name
          numericalFilter {
            field
            value
          }
          rangeFilter {
            field
            value {
              from
              to
            }
          }
          textFilter {
            field
            value
          }
          textArrFilter {
            field
            value
          }
        }
        exclude {
          brand
          gender
          campaign
          catalog_type
          category
          color
          discount {
            from
            to
          }
          price {
            from
            to
          }
          promotion_price {
            from
            to
          }
          product_age
          segment
          shop_type
          size
          sku
        }
        include {
          brand
          gender
          campaign
          catalog_type
          category
          color
          discount {
            from
            to
          }
          price {
            from
            to
          }
          promotion_price {
            from
            to
          }
          product_age
          segment
          shop_type
          size
          sku
        }
      }
      strategy
    }
    recommendation_type
    scenario
    screen_name
    updated_at
    updated_by
    url
  }
`;
export const ProductExpandedFragmentDoc = gql`
  fragment ProductExpanded on Product {
    id
    groupId
    soldCount
    availability
    description
    customScore
    categoryNamedPath
    mainCategories {
      id
      name
      slug
    }
    categories {
      id
      name
      slug
    }
    skus
    price {
      current
      currency
      previous
      discount
    }
    publishedAt
    seller {
      id
    }
    stockCount
    brand {
      id
      name
      slug
    }
    stockAttributes {
      name
      value
    }
    searchableAttributes {
      name
      value
    }
    imagePath
    title
    url
    bigQueryStats {
      name
      value
    }
    stats {
      name
      value
    }
    catarankPrediction {
      name
      value
    }
    sizePopularity
    numerics {
      name
      value
    }
  }
`;
export const SearchConfigExpandedFragmentDoc = gql`
  fragment SearchConfigExpanded on SearchConfiguration {
    id
    name
    projectId
    blocked
    url
    versions {
      id
      name
      description
      createdAt
      createdBy {
        id
      }
      modifiedBy
      updatedAt
      filterAttributes {
        id
        fieldName
        rule
        numValue
        strValue
        strArrValue
      }
      personalisedAttributes {
        id
        fieldName
        weight
      }
      rankAttributes {
        id
        fieldName
        weight
        rule
        numValue
        strValue
        strArrValue
      }
      status
    }
  }
`;
export const SearchOptimizationExpandedFragmentDoc = gql`
  fragment SearchOptimizationExpanded on SearchOptimization {
    id
    query
    output
    count
    createdBy {
      id
      name
    }
    modifiedBy {
      id
      name
    }
    createdAt
    updatedAt
    state
  }
`;
export const CreateUserDocument = gql`
  mutation CreateUser($user: CreateUserInput!) {
    createUser(user: $user)
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<Types.CreateUserMutation, Types.CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateUserMutation, Types.CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateUserMutation, Types.CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<Types.CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateUserMutation,
  Types.CreateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<Types.DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteUserMutation,
  Types.DeleteUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: ID!, $user: UpdateUserInput!) {
    updateUser(id: $id, user: $user)
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateUserMutation,
  Types.UpdateUserMutationVariables
>;
export const GetUsersDocument = gql`
  query GetUsers($filters: UsersFiltersInput, $pagination: PaginationInput!) {
    users(filters: $filters, pagination: $pagination) {
      data {
        id
        email
        name
        environments {
          id
          name
          key
        }
        resources {
          id
          title
          name
          editable_by_users
        }
        modifier {
          id
          name
        }
        role
        verified
        created_at
        updated_at
      }
      total
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetUsersQuery, Types.GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetUsersQuery, Types.GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUsersQuery, Types.GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetUsersQuery, Types.GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<Types.GetUsersQuery, Types.GetUsersQueryVariables>;
export const LoginDocument = gql`
  mutation Login($token: String!) {
    login(token: $token) {
      ... on FailedLoginResponse {
        reason
      }
      ... on SuccessfulLoginResponse {
        token
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>;
export const GetGoogleClientIdDocument = gql`
  query GetGoogleClientId {
    configuration {
      googleClientId
    }
  }
`;

/**
 * __useGetGoogleClientIdQuery__
 *
 * To run a query within a React component, call `useGetGoogleClientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleClientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleClientIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleClientIdQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetGoogleClientIdQuery, Types.GetGoogleClientIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetGoogleClientIdQuery, Types.GetGoogleClientIdQueryVariables>(
    GetGoogleClientIdDocument,
    options,
  );
}
export function useGetGoogleClientIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGoogleClientIdQuery, Types.GetGoogleClientIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetGoogleClientIdQuery, Types.GetGoogleClientIdQueryVariables>(
    GetGoogleClientIdDocument,
    options,
  );
}
export type GetGoogleClientIdQueryHookResult = ReturnType<typeof useGetGoogleClientIdQuery>;
export type GetGoogleClientIdLazyQueryHookResult = ReturnType<typeof useGetGoogleClientIdLazyQuery>;
export type GetGoogleClientIdQueryResult = Apollo.QueryResult<
  Types.GetGoogleClientIdQuery,
  Types.GetGoogleClientIdQueryVariables
>;
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      id
      name
      email
      role
      environments {
        id
        key
        name
      }
      resources {
        id
        title
        name
        editable_by_users
      }
    }
  }
`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<Types.ViewerQuery, Types.ViewerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ViewerQuery, Types.ViewerQueryVariables>(ViewerDocument, options);
}
export function useViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ViewerQuery, Types.ViewerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ViewerQuery, Types.ViewerQueryVariables>(ViewerDocument, options);
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<Types.ViewerQuery, Types.ViewerQueryVariables>;
export const CreateCampaignDocument = gql`
  mutation CreateCampaign($params: CreateCampaignInput!) {
    createCampaign(input: $params) {
      ... on Campaign {
        ...CampaignExpanded
      }
    }
  }
  ${CampaignExpandedFragmentDoc}
`;
export type CreateCampaignMutationFn = Apollo.MutationFunction<
  Types.CreateCampaignMutation,
  Types.CreateCampaignMutationVariables
>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateCampaignMutation, Types.CreateCampaignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateCampaignMutation, Types.CreateCampaignMutationVariables>(
    CreateCampaignDocument,
    options,
  );
}
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<Types.CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCampaignMutation,
  Types.CreateCampaignMutationVariables
>;
export const DeleteCampaignDocument = gql`
  mutation DeleteCampaign($input: DeleteCampaignInput!) {
    deleteCampaign(input: $input) {
      ... on CampaignDeletedSuccessResponse {
        campaignId
      }
    }
  }
`;
export type DeleteCampaignMutationFn = Apollo.MutationFunction<
  Types.DeleteCampaignMutation,
  Types.DeleteCampaignMutationVariables
>;

/**
 * __useDeleteCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignMutation, { data, loading, error }] = useDeleteCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteCampaignMutation, Types.DeleteCampaignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteCampaignMutation, Types.DeleteCampaignMutationVariables>(
    DeleteCampaignDocument,
    options,
  );
}
export type DeleteCampaignMutationHookResult = ReturnType<typeof useDeleteCampaignMutation>;
export type DeleteCampaignMutationResult = Apollo.MutationResult<Types.DeleteCampaignMutation>;
export type DeleteCampaignMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteCampaignMutation,
  Types.DeleteCampaignMutationVariables
>;
export const UpdateCampaignDocument = gql`
  mutation UpdateCampaign($params: UpdateCampaignInput!) {
    updateCampaign(input: $params) {
      ... on Campaign {
        ...CampaignExpanded
      }
    }
  }
  ${CampaignExpandedFragmentDoc}
`;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<
  Types.UpdateCampaignMutation,
  Types.UpdateCampaignMutationVariables
>;

/**
 * __useUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMutation, { data, loading, error }] = useUpdateCampaignMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateCampaignMutation, Types.UpdateCampaignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateCampaignMutation, Types.UpdateCampaignMutationVariables>(
    UpdateCampaignDocument,
    options,
  );
}
export type UpdateCampaignMutationHookResult = ReturnType<typeof useUpdateCampaignMutation>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<Types.UpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCampaignMutation,
  Types.UpdateCampaignMutationVariables
>;
export const GetCampaignDocument = gql`
  query GetCampaign($params: GetCampaignParams!) {
    campaign(params: $params) {
      ... on Campaign {
        ...CampaignExpanded
      }
    }
  }
  ${CampaignExpandedFragmentDoc}
`;

/**
 * __useGetCampaignQuery__
 *
 * To run a query within a React component, call `useGetCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetCampaignQuery, Types.GetCampaignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetCampaignQuery, Types.GetCampaignQueryVariables>(GetCampaignDocument, options);
}
export function useGetCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCampaignQuery, Types.GetCampaignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetCampaignQuery, Types.GetCampaignQueryVariables>(GetCampaignDocument, options);
}
export type GetCampaignQueryHookResult = ReturnType<typeof useGetCampaignQuery>;
export type GetCampaignLazyQueryHookResult = ReturnType<typeof useGetCampaignLazyQuery>;
export type GetCampaignQueryResult = Apollo.QueryResult<Types.GetCampaignQuery, Types.GetCampaignQueryVariables>;
export const GetCampaignsDocument = gql`
  query GetCampaigns($filter: CampaignsFilter!, $pagination: PaginationInput!) {
    campaigns(filter: $filter, pagination: $pagination) {
      data {
        id
        name
        value
        type
        createdAt
        createdBy
        updatedAt
        modifiedBy
      }
      total
    }
  }
`;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCampaignsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetCampaignsQuery, Types.GetCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetCampaignsQuery, Types.GetCampaignsQueryVariables>(GetCampaignsDocument, options);
}
export function useGetCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCampaignsQuery, Types.GetCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetCampaignsQuery, Types.GetCampaignsQueryVariables>(GetCampaignsDocument, options);
}
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>;
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>;
export type GetCampaignsQueryResult = Apollo.QueryResult<Types.GetCampaignsQuery, Types.GetCampaignsQueryVariables>;
export const GetCampaignPreviewDocument = gql`
  query GetCampaignPreview($previewParams: CampaignPreviewInput!, $configParams: GetCampaignParams!) {
    campaignPreview(params: $previewParams) {
      total
      previews {
        id
        name
        categories {
          name
        }
        brand {
          name
        }
        imagePath
        price {
          currency
          current
          previous
        }
        publishedDate
        customScore
        skus
        stock
        price {
          currency
          current
          previous
        }
      }
    }
    campaign(params: $configParams) {
      ... on Campaign {
        ...CampaignExpanded
      }
    }
  }
  ${CampaignExpandedFragmentDoc}
`;

/**
 * __useGetCampaignPreviewQuery__
 *
 * To run a query within a React component, call `useGetCampaignPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignPreviewQuery({
 *   variables: {
 *      previewParams: // value for 'previewParams'
 *      configParams: // value for 'configParams'
 *   },
 * });
 */
export function useGetCampaignPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetCampaignPreviewQuery, Types.GetCampaignPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetCampaignPreviewQuery, Types.GetCampaignPreviewQueryVariables>(
    GetCampaignPreviewDocument,
    options,
  );
}
export function useGetCampaignPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCampaignPreviewQuery, Types.GetCampaignPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetCampaignPreviewQuery, Types.GetCampaignPreviewQueryVariables>(
    GetCampaignPreviewDocument,
    options,
  );
}
export type GetCampaignPreviewQueryHookResult = ReturnType<typeof useGetCampaignPreviewQuery>;
export type GetCampaignPreviewLazyQueryHookResult = ReturnType<typeof useGetCampaignPreviewLazyQuery>;
export type GetCampaignPreviewQueryResult = Apollo.QueryResult<
  Types.GetCampaignPreviewQuery,
  Types.GetCampaignPreviewQueryVariables
>;
export const GetChartGroupsDocument = gql`
  query GetChartGroups($params: GetAnalyticsInput!) {
    chartGroups(params: $params) {
      id
      name
      section
      region {
        id
      }
      charts {
        id
        options {
          title
          subtitle
          description
          size
          xAxisName
          yAxisName
          yAxisLabelPrefix
          yAxisLabelPostfix
          yAxisBeginAtZero
          yAxisDecimalPlaces
        }
        region {
          id
        }
        section
        type
        data {
          labels {
            values
          }
          ranges {
            name
            values
            options {
              color
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetChartGroupsQuery__
 *
 * To run a query within a React component, call `useGetChartGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartGroupsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetChartGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetChartGroupsQuery, Types.GetChartGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetChartGroupsQuery, Types.GetChartGroupsQueryVariables>(
    GetChartGroupsDocument,
    options,
  );
}
export function useGetChartGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChartGroupsQuery, Types.GetChartGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetChartGroupsQuery, Types.GetChartGroupsQueryVariables>(
    GetChartGroupsDocument,
    options,
  );
}
export type GetChartGroupsQueryHookResult = ReturnType<typeof useGetChartGroupsQuery>;
export type GetChartGroupsLazyQueryHookResult = ReturnType<typeof useGetChartGroupsLazyQuery>;
export type GetChartGroupsQueryResult = Apollo.QueryResult<
  Types.GetChartGroupsQuery,
  Types.GetChartGroupsQueryVariables
>;
export const GetEnvironmentsDocument = gql`
  query GetEnvironments {
    configuration {
      environments {
        id
        name
        key
      }
    }
  }
`;

/**
 * __useGetEnvironmentsQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnvironmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetEnvironmentsQuery, Types.GetEnvironmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetEnvironmentsQuery, Types.GetEnvironmentsQueryVariables>(
    GetEnvironmentsDocument,
    options,
  );
}
export function useGetEnvironmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEnvironmentsQuery, Types.GetEnvironmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetEnvironmentsQuery, Types.GetEnvironmentsQueryVariables>(
    GetEnvironmentsDocument,
    options,
  );
}
export type GetEnvironmentsQueryHookResult = ReturnType<typeof useGetEnvironmentsQuery>;
export type GetEnvironmentsLazyQueryHookResult = ReturnType<typeof useGetEnvironmentsLazyQuery>;
export type GetEnvironmentsQueryResult = Apollo.QueryResult<
  Types.GetEnvironmentsQuery,
  Types.GetEnvironmentsQueryVariables
>;
export const GetDatadogConfigurationDocument = gql`
  query GetDatadogConfiguration {
    configuration {
      datadog {
        applicationId
        clientToken
        site
        service
      }
    }
  }
`;

/**
 * __useGetDatadogConfigurationQuery__
 *
 * To run a query within a React component, call `useGetDatadogConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatadogConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatadogConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDatadogConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetDatadogConfigurationQuery,
    Types.GetDatadogConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetDatadogConfigurationQuery, Types.GetDatadogConfigurationQueryVariables>(
    GetDatadogConfigurationDocument,
    options,
  );
}
export function useGetDatadogConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetDatadogConfigurationQuery,
    Types.GetDatadogConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetDatadogConfigurationQuery, Types.GetDatadogConfigurationQueryVariables>(
    GetDatadogConfigurationDocument,
    options,
  );
}
export type GetDatadogConfigurationQueryHookResult = ReturnType<typeof useGetDatadogConfigurationQuery>;
export type GetDatadogConfigurationLazyQueryHookResult = ReturnType<typeof useGetDatadogConfigurationLazyQuery>;
export type GetDatadogConfigurationQueryResult = Apollo.QueryResult<
  Types.GetDatadogConfigurationQuery,
  Types.GetDatadogConfigurationQueryVariables
>;
export const GetSearchPreviewDocument = gql`
  query GetSearchPreview(
    $previewParams: SearchConfigPreviewInput!
    $searchConfigParams: GetSearchConfigurationParams!
  ) {
    searchConfigPreview(params: $previewParams) {
      total
      previews {
        id
        name
        categories {
          name
        }
        additionalAttributes {
          name
          value
        }
        brand {
          name
        }
        imagePath
        price {
          currency
          current
          previous
        }
        publishedDate
        customScore
        skus
        stock
        price {
          currency
          current
          previous
        }
        retrievalScore
        rankingScore
        scoreExplanation
        sizePopularity
      }
    }
    searchConfiguration(params: $searchConfigParams) {
      ... on SearchConfiguration {
        ...SearchConfigExpanded
      }
    }
  }
  ${SearchConfigExpandedFragmentDoc}
`;

/**
 * __useGetSearchPreviewQuery__
 *
 * To run a query within a React component, call `useGetSearchPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchPreviewQuery({
 *   variables: {
 *      previewParams: // value for 'previewParams'
 *      searchConfigParams: // value for 'searchConfigParams'
 *   },
 * });
 */
export function useGetSearchPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetSearchPreviewQuery, Types.GetSearchPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSearchPreviewQuery, Types.GetSearchPreviewQueryVariables>(
    GetSearchPreviewDocument,
    options,
  );
}
export function useGetSearchPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchPreviewQuery, Types.GetSearchPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSearchPreviewQuery, Types.GetSearchPreviewQueryVariables>(
    GetSearchPreviewDocument,
    options,
  );
}
export type GetSearchPreviewQueryHookResult = ReturnType<typeof useGetSearchPreviewQuery>;
export type GetSearchPreviewLazyQueryHookResult = ReturnType<typeof useGetSearchPreviewLazyQuery>;
export type GetSearchPreviewQueryResult = Apollo.QueryResult<
  Types.GetSearchPreviewQuery,
  Types.GetSearchPreviewQueryVariables
>;
export const GetFilterAttributeValuesDocument = gql`
  query GetFilterAttributeValues($params: AvailableProductAttributeValuesParams!) {
    feedFilterAttributeValues(params: $params) {
      label
      value
    }
  }
`;

/**
 * __useGetFilterAttributeValuesQuery__
 *
 * To run a query within a React component, call `useGetFilterAttributeValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterAttributeValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterAttributeValuesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetFilterAttributeValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetFilterAttributeValuesQuery,
    Types.GetFilterAttributeValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetFilterAttributeValuesQuery, Types.GetFilterAttributeValuesQueryVariables>(
    GetFilterAttributeValuesDocument,
    options,
  );
}
export function useGetFilterAttributeValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetFilterAttributeValuesQuery,
    Types.GetFilterAttributeValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetFilterAttributeValuesQuery, Types.GetFilterAttributeValuesQueryVariables>(
    GetFilterAttributeValuesDocument,
    options,
  );
}
export type GetFilterAttributeValuesQueryHookResult = ReturnType<typeof useGetFilterAttributeValuesQuery>;
export type GetFilterAttributeValuesLazyQueryHookResult = ReturnType<typeof useGetFilterAttributeValuesLazyQuery>;
export type GetFilterAttributeValuesQueryResult = Apollo.QueryResult<
  Types.GetFilterAttributeValuesQuery,
  Types.GetFilterAttributeValuesQueryVariables
>;
export const GetFilterAttributesDocument = gql`
  query GetFilterAttributes($params: AvailableProductAttributeParams!) {
    feedFilterAttributes(params: $params) {
      id
      label
      value
      isDynamicAttribute
      rules {
        id
        label
        value
        type
      }
    }
  }
`;

/**
 * __useGetFilterAttributesQuery__
 *
 * To run a query within a React component, call `useGetFilterAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterAttributesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetFilterAttributesQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetFilterAttributesQuery, Types.GetFilterAttributesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetFilterAttributesQuery, Types.GetFilterAttributesQueryVariables>(
    GetFilterAttributesDocument,
    options,
  );
}
export function useGetFilterAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilterAttributesQuery, Types.GetFilterAttributesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetFilterAttributesQuery, Types.GetFilterAttributesQueryVariables>(
    GetFilterAttributesDocument,
    options,
  );
}
export type GetFilterAttributesQueryHookResult = ReturnType<typeof useGetFilterAttributesQuery>;
export type GetFilterAttributesLazyQueryHookResult = ReturnType<typeof useGetFilterAttributesLazyQuery>;
export type GetFilterAttributesQueryResult = Apollo.QueryResult<
  Types.GetFilterAttributesQuery,
  Types.GetFilterAttributesQueryVariables
>;
export const CreateFeedDocument = gql`
  mutation CreateFeed($feed: CreateFeedInput!) {
    createFeed(feed: $feed) {
      ...CustomFeedExpanded
    }
  }
  ${CustomFeedExpandedFragmentDoc}
`;
export type CreateFeedMutationFn = Apollo.MutationFunction<Types.CreateFeedMutation, Types.CreateFeedMutationVariables>;

/**
 * __useCreateFeedMutation__
 *
 * To run a mutation, you first call `useCreateFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedMutation, { data, loading, error }] = useCreateFeedMutation({
 *   variables: {
 *      feed: // value for 'feed'
 *   },
 * });
 */
export function useCreateFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateFeedMutation, Types.CreateFeedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateFeedMutation, Types.CreateFeedMutationVariables>(CreateFeedDocument, options);
}
export type CreateFeedMutationHookResult = ReturnType<typeof useCreateFeedMutation>;
export type CreateFeedMutationResult = Apollo.MutationResult<Types.CreateFeedMutation>;
export type CreateFeedMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateFeedMutation,
  Types.CreateFeedMutationVariables
>;
export const DeleteFeedDocument = gql`
  mutation DeleteFeed($params: DeleteCustomFeeds!) {
    deleteCustomFeeds(params: $params) {
      id
    }
  }
`;
export type DeleteFeedMutationFn = Apollo.MutationFunction<Types.DeleteFeedMutation, Types.DeleteFeedMutationVariables>;

/**
 * __useDeleteFeedMutation__
 *
 * To run a mutation, you first call `useDeleteFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedMutation, { data, loading, error }] = useDeleteFeedMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useDeleteFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteFeedMutation, Types.DeleteFeedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteFeedMutation, Types.DeleteFeedMutationVariables>(DeleteFeedDocument, options);
}
export type DeleteFeedMutationHookResult = ReturnType<typeof useDeleteFeedMutation>;
export type DeleteFeedMutationResult = Apollo.MutationResult<Types.DeleteFeedMutation>;
export type DeleteFeedMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteFeedMutation,
  Types.DeleteFeedMutationVariables
>;
export const UpdateFeedDocument = gql`
  mutation UpdateFeed($id: ID!, $feed: UpdateFeedInput!) {
    updateFeed(id: $id, feed: $feed) {
      ...CustomFeedExpanded
    }
  }
  ${CustomFeedExpandedFragmentDoc}
`;
export type UpdateFeedMutationFn = Apollo.MutationFunction<Types.UpdateFeedMutation, Types.UpdateFeedMutationVariables>;

/**
 * __useUpdateFeedMutation__
 *
 * To run a mutation, you first call `useUpdateFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedMutation, { data, loading, error }] = useUpdateFeedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      feed: // value for 'feed'
 *   },
 * });
 */
export function useUpdateFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateFeedMutation, Types.UpdateFeedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateFeedMutation, Types.UpdateFeedMutationVariables>(UpdateFeedDocument, options);
}
export type UpdateFeedMutationHookResult = ReturnType<typeof useUpdateFeedMutation>;
export type UpdateFeedMutationResult = Apollo.MutationResult<Types.UpdateFeedMutation>;
export type UpdateFeedMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateFeedMutation,
  Types.UpdateFeedMutationVariables
>;
export const CustomFeedPreviewDocument = gql`
  query CustomFeedPreview($params: CustomFeedPreviewRequest!) {
    customFeedPreview(params: $params) {
      cursor
      final_url
      has_next_page
      id
      status
      message
      items {
        id
        categories {
          id
          name
        }
        custom_score
        group_id
        published_at
        sales_count
        scheme_version
        sku
        stock_count
        title
        url
        version
        discount
        gender
        category_path
        skus
        seller {
          id
          internal_score
          international
          name
          rating
          reviews_count
          type
        }
        price {
          currency
          current
          previous
        }
        payload {
          additionalProp3 {
            type_url
            value
          }
          additionalProp2 {
            type_url
            value
          }
          additionalProp1 {
            type_url
            value
          }
        }
        numerics {
          additionalProp1
          additionalProp2
          additionalProp3
        }
        images
        colors
        brand {
          id
          name
          slug
        }
        ctl_algorithm
        strategy
      }
      total
      seedProduct {
        ...ProductExpanded
      }
    }
  }
  ${ProductExpandedFragmentDoc}
`;

/**
 * __useCustomFeedPreviewQuery__
 *
 * To run a query within a React component, call `useCustomFeedPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFeedPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFeedPreviewQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCustomFeedPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<Types.CustomFeedPreviewQuery, Types.CustomFeedPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CustomFeedPreviewQuery, Types.CustomFeedPreviewQueryVariables>(
    CustomFeedPreviewDocument,
    options,
  );
}
export function useCustomFeedPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomFeedPreviewQuery, Types.CustomFeedPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CustomFeedPreviewQuery, Types.CustomFeedPreviewQueryVariables>(
    CustomFeedPreviewDocument,
    options,
  );
}
export type CustomFeedPreviewQueryHookResult = ReturnType<typeof useCustomFeedPreviewQuery>;
export type CustomFeedPreviewLazyQueryHookResult = ReturnType<typeof useCustomFeedPreviewLazyQuery>;
export type CustomFeedPreviewQueryResult = Apollo.QueryResult<
  Types.CustomFeedPreviewQuery,
  Types.CustomFeedPreviewQueryVariables
>;
export const GetFeedDocument = gql`
  query GetFeed($params: GetCustomFeedInput!) {
    customFeed(params: $params) {
      ...CustomFeedExpanded
    }
  }
  ${CustomFeedExpandedFragmentDoc}
`;

/**
 * __useGetFeedQuery__
 *
 * To run a query within a React component, call `useGetFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetFeedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFeedQuery, Types.GetFeedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetFeedQuery, Types.GetFeedQueryVariables>(GetFeedDocument, options);
}
export function useGetFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFeedQuery, Types.GetFeedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetFeedQuery, Types.GetFeedQueryVariables>(GetFeedDocument, options);
}
export type GetFeedQueryHookResult = ReturnType<typeof useGetFeedQuery>;
export type GetFeedLazyQueryHookResult = ReturnType<typeof useGetFeedLazyQuery>;
export type GetFeedQueryResult = Apollo.QueryResult<Types.GetFeedQuery, Types.GetFeedQueryVariables>;
export const GetFeedsDocument = gql`
  query GetFeeds($filters: CustomFeedsFilter!, $pagination: PaginationInput!) {
    customFeeds(filters: $filters, pagination: $pagination) {
      data {
        id
        updated_by
        name
        url
        updated_at
        state
      }
      total
    }
  }
`;

/**
 * __useGetFeedsQuery__
 *
 * To run a query within a React component, call `useGetFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetFeedsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetFeedsQuery, Types.GetFeedsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetFeedsQuery, Types.GetFeedsQueryVariables>(GetFeedsDocument, options);
}
export function useGetFeedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFeedsQuery, Types.GetFeedsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetFeedsQuery, Types.GetFeedsQueryVariables>(GetFeedsDocument, options);
}
export type GetFeedsQueryHookResult = ReturnType<typeof useGetFeedsQuery>;
export type GetFeedsLazyQueryHookResult = ReturnType<typeof useGetFeedsLazyQuery>;
export type GetFeedsQueryResult = Apollo.QueryResult<Types.GetFeedsQuery, Types.GetFeedsQueryVariables>;
export const CreateNewsletterDocument = gql`
  mutation CreateNewsletter($newsletter: CreateNewsletterInput!) {
    createNewsletter(newsletter: $newsletter) {
      ...CustomFeedExpanded
    }
  }
  ${CustomFeedExpandedFragmentDoc}
`;
export type CreateNewsletterMutationFn = Apollo.MutationFunction<
  Types.CreateNewsletterMutation,
  Types.CreateNewsletterMutationVariables
>;

/**
 * __useCreateNewsletterMutation__
 *
 * To run a mutation, you first call `useCreateNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsletterMutation, { data, loading, error }] = useCreateNewsletterMutation({
 *   variables: {
 *      newsletter: // value for 'newsletter'
 *   },
 * });
 */
export function useCreateNewsletterMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateNewsletterMutation, Types.CreateNewsletterMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateNewsletterMutation, Types.CreateNewsletterMutationVariables>(
    CreateNewsletterDocument,
    options,
  );
}
export type CreateNewsletterMutationHookResult = ReturnType<typeof useCreateNewsletterMutation>;
export type CreateNewsletterMutationResult = Apollo.MutationResult<Types.CreateNewsletterMutation>;
export type CreateNewsletterMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateNewsletterMutation,
  Types.CreateNewsletterMutationVariables
>;
export const DeleteNewsletterDocument = gql`
  mutation DeleteNewsletter($params: DeleteCustomFeeds!) {
    deleteCustomFeeds(params: $params) {
      id
    }
  }
`;
export type DeleteNewsletterMutationFn = Apollo.MutationFunction<
  Types.DeleteNewsletterMutation,
  Types.DeleteNewsletterMutationVariables
>;

/**
 * __useDeleteNewsletterMutation__
 *
 * To run a mutation, you first call `useDeleteNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsletterMutation, { data, loading, error }] = useDeleteNewsletterMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useDeleteNewsletterMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteNewsletterMutation, Types.DeleteNewsletterMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteNewsletterMutation, Types.DeleteNewsletterMutationVariables>(
    DeleteNewsletterDocument,
    options,
  );
}
export type DeleteNewsletterMutationHookResult = ReturnType<typeof useDeleteNewsletterMutation>;
export type DeleteNewsletterMutationResult = Apollo.MutationResult<Types.DeleteNewsletterMutation>;
export type DeleteNewsletterMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteNewsletterMutation,
  Types.DeleteNewsletterMutationVariables
>;
export const UpdateNewsletterDocument = gql`
  mutation UpdateNewsletter($id: ID!, $newsletter: UpdateNewsletterInput!) {
    updateNewsletter(id: $id, newsletter: $newsletter) {
      ...CustomFeedExpanded
    }
  }
  ${CustomFeedExpandedFragmentDoc}
`;
export type UpdateNewsletterMutationFn = Apollo.MutationFunction<
  Types.UpdateNewsletterMutation,
  Types.UpdateNewsletterMutationVariables
>;

/**
 * __useUpdateNewsletterMutation__
 *
 * To run a mutation, you first call `useUpdateNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsletterMutation, { data, loading, error }] = useUpdateNewsletterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newsletter: // value for 'newsletter'
 *   },
 * });
 */
export function useUpdateNewsletterMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateNewsletterMutation, Types.UpdateNewsletterMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateNewsletterMutation, Types.UpdateNewsletterMutationVariables>(
    UpdateNewsletterDocument,
    options,
  );
}
export type UpdateNewsletterMutationHookResult = ReturnType<typeof useUpdateNewsletterMutation>;
export type UpdateNewsletterMutationResult = Apollo.MutationResult<Types.UpdateNewsletterMutation>;
export type UpdateNewsletterMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateNewsletterMutation,
  Types.UpdateNewsletterMutationVariables
>;
export const GetNewsletterDocument = gql`
  query GetNewsletter($params: GetCustomFeedInput!) {
    customFeed(params: $params) {
      ...CustomFeedExpanded
    }
  }
  ${CustomFeedExpandedFragmentDoc}
`;

/**
 * __useGetNewsletterQuery__
 *
 * To run a query within a React component, call `useGetNewsletterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsletterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsletterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetNewsletterQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetNewsletterQuery, Types.GetNewsletterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetNewsletterQuery, Types.GetNewsletterQueryVariables>(GetNewsletterDocument, options);
}
export function useGetNewsletterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNewsletterQuery, Types.GetNewsletterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetNewsletterQuery, Types.GetNewsletterQueryVariables>(
    GetNewsletterDocument,
    options,
  );
}
export type GetNewsletterQueryHookResult = ReturnType<typeof useGetNewsletterQuery>;
export type GetNewsletterLazyQueryHookResult = ReturnType<typeof useGetNewsletterLazyQuery>;
export type GetNewsletterQueryResult = Apollo.QueryResult<Types.GetNewsletterQuery, Types.GetNewsletterQueryVariables>;
export const GetNewslettersDocument = gql`
  query GetNewsletters($filters: CustomFeedsFilter!, $pagination: PaginationInput!) {
    customFeeds(filters: $filters, pagination: $pagination) {
      data {
        id
        updated_by
        name
        url
        updated_at
        state
      }
      total
    }
  }
`;

/**
 * __useGetNewslettersQuery__
 *
 * To run a query within a React component, call `useGetNewslettersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewslettersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewslettersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetNewslettersQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetNewslettersQuery, Types.GetNewslettersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetNewslettersQuery, Types.GetNewslettersQueryVariables>(
    GetNewslettersDocument,
    options,
  );
}
export function useGetNewslettersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNewslettersQuery, Types.GetNewslettersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetNewslettersQuery, Types.GetNewslettersQueryVariables>(
    GetNewslettersDocument,
    options,
  );
}
export type GetNewslettersQueryHookResult = ReturnType<typeof useGetNewslettersQuery>;
export type GetNewslettersLazyQueryHookResult = ReturnType<typeof useGetNewslettersLazyQuery>;
export type GetNewslettersQueryResult = Apollo.QueryResult<
  Types.GetNewslettersQuery,
  Types.GetNewslettersQueryVariables
>;
export const GetProductDocument = gql`
  query GetProduct($params: GetProductInput!) {
    product(params: $params) {
      ... on SuccessfulProductFetchResponse {
        product {
          ...ProductExpanded
        }
      }
      ... on FailedProductFetchResponse {
        reason
      }
    }
  }
  ${ProductExpandedFragmentDoc}
`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetProductQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetProductQuery, Types.GetProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetProductQuery, Types.GetProductQueryVariables>(GetProductDocument, options);
}
export function useGetProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProductQuery, Types.GetProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetProductQuery, Types.GetProductQueryVariables>(GetProductDocument, options);
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<Types.GetProductQuery, Types.GetProductQueryVariables>;
export const ActivateSearchConfigVersionDocument = gql`
  mutation ActivateSearchConfigVersion($input: ActivateSearchConfigVersionInput!) {
    activateSearchConfigVersion(input: $input) {
      ... on SuccessfulStatusChangeResponse {
        status
      }
    }
  }
`;
export type ActivateSearchConfigVersionMutationFn = Apollo.MutationFunction<
  Types.ActivateSearchConfigVersionMutation,
  Types.ActivateSearchConfigVersionMutationVariables
>;

/**
 * __useActivateSearchConfigVersionMutation__
 *
 * To run a mutation, you first call `useActivateSearchConfigVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSearchConfigVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSearchConfigVersionMutation, { data, loading, error }] = useActivateSearchConfigVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateSearchConfigVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ActivateSearchConfigVersionMutation,
    Types.ActivateSearchConfigVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ActivateSearchConfigVersionMutation,
    Types.ActivateSearchConfigVersionMutationVariables
  >(ActivateSearchConfigVersionDocument, options);
}
export type ActivateSearchConfigVersionMutationHookResult = ReturnType<typeof useActivateSearchConfigVersionMutation>;
export type ActivateSearchConfigVersionMutationResult =
  Apollo.MutationResult<Types.ActivateSearchConfigVersionMutation>;
export type ActivateSearchConfigVersionMutationOptions = Apollo.BaseMutationOptions<
  Types.ActivateSearchConfigVersionMutation,
  Types.ActivateSearchConfigVersionMutationVariables
>;
export const CreateSearchConfigurationDocument = gql`
  mutation CreateSearchConfiguration($params: CreateSearchConfigInput!) {
    createSearchConfig(input: $params) {
      ... on SearchConfiguration {
        ...SearchConfigExpanded
      }
    }
  }
  ${SearchConfigExpandedFragmentDoc}
`;
export type CreateSearchConfigurationMutationFn = Apollo.MutationFunction<
  Types.CreateSearchConfigurationMutation,
  Types.CreateSearchConfigurationMutationVariables
>;

/**
 * __useCreateSearchConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateSearchConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchConfigurationMutation, { data, loading, error }] = useCreateSearchConfigurationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateSearchConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateSearchConfigurationMutation,
    Types.CreateSearchConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateSearchConfigurationMutation, Types.CreateSearchConfigurationMutationVariables>(
    CreateSearchConfigurationDocument,
    options,
  );
}
export type CreateSearchConfigurationMutationHookResult = ReturnType<typeof useCreateSearchConfigurationMutation>;
export type CreateSearchConfigurationMutationResult = Apollo.MutationResult<Types.CreateSearchConfigurationMutation>;
export type CreateSearchConfigurationMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateSearchConfigurationMutation,
  Types.CreateSearchConfigurationMutationVariables
>;
export const DeactivateSearchConfigVersionDocument = gql`
  mutation DeactivateSearchConfigVersion($input: DeactivateSearchConfigVersionInput!) {
    deactivateSearchConfigVersion(input: $input) {
      ... on SuccessfulStatusChangeResponse {
        status
      }
    }
  }
`;
export type DeactivateSearchConfigVersionMutationFn = Apollo.MutationFunction<
  Types.DeactivateSearchConfigVersionMutation,
  Types.DeactivateSearchConfigVersionMutationVariables
>;

/**
 * __useDeactivateSearchConfigVersionMutation__
 *
 * To run a mutation, you first call `useDeactivateSearchConfigVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateSearchConfigVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateSearchConfigVersionMutation, { data, loading, error }] = useDeactivateSearchConfigVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateSearchConfigVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeactivateSearchConfigVersionMutation,
    Types.DeactivateSearchConfigVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeactivateSearchConfigVersionMutation,
    Types.DeactivateSearchConfigVersionMutationVariables
  >(DeactivateSearchConfigVersionDocument, options);
}
export type DeactivateSearchConfigVersionMutationHookResult = ReturnType<
  typeof useDeactivateSearchConfigVersionMutation
>;
export type DeactivateSearchConfigVersionMutationResult =
  Apollo.MutationResult<Types.DeactivateSearchConfigVersionMutation>;
export type DeactivateSearchConfigVersionMutationOptions = Apollo.BaseMutationOptions<
  Types.DeactivateSearchConfigVersionMutation,
  Types.DeactivateSearchConfigVersionMutationVariables
>;
export const DeleteSearchConfigurationVersionDocument = gql`
  mutation DeleteSearchConfigurationVersion($input: DeleteSearchConfigVersionInput!) {
    deleteSearchConfigVersion(input: $input) {
      ... on SuccessfulDeletionResponse {
        id
      }
    }
  }
`;
export type DeleteSearchConfigurationVersionMutationFn = Apollo.MutationFunction<
  Types.DeleteSearchConfigurationVersionMutation,
  Types.DeleteSearchConfigurationVersionMutationVariables
>;

/**
 * __useDeleteSearchConfigurationVersionMutation__
 *
 * To run a mutation, you first call `useDeleteSearchConfigurationVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchConfigurationVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchConfigurationVersionMutation, { data, loading, error }] = useDeleteSearchConfigurationVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSearchConfigurationVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteSearchConfigurationVersionMutation,
    Types.DeleteSearchConfigurationVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteSearchConfigurationVersionMutation,
    Types.DeleteSearchConfigurationVersionMutationVariables
  >(DeleteSearchConfigurationVersionDocument, options);
}
export type DeleteSearchConfigurationVersionMutationHookResult = ReturnType<
  typeof useDeleteSearchConfigurationVersionMutation
>;
export type DeleteSearchConfigurationVersionMutationResult =
  Apollo.MutationResult<Types.DeleteSearchConfigurationVersionMutation>;
export type DeleteSearchConfigurationVersionMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteSearchConfigurationVersionMutation,
  Types.DeleteSearchConfigurationVersionMutationVariables
>;
export const DeleteSearchConfigurationDocument = gql`
  mutation DeleteSearchConfiguration($input: DeleteSearchConfigInput!) {
    deleteSearchConfig(input: $input) {
      ... on SuccessfulDeletionResponse {
        id
      }
    }
  }
`;
export type DeleteSearchConfigurationMutationFn = Apollo.MutationFunction<
  Types.DeleteSearchConfigurationMutation,
  Types.DeleteSearchConfigurationMutationVariables
>;

/**
 * __useDeleteSearchConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteSearchConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchConfigurationMutation, { data, loading, error }] = useDeleteSearchConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSearchConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteSearchConfigurationMutation,
    Types.DeleteSearchConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteSearchConfigurationMutation, Types.DeleteSearchConfigurationMutationVariables>(
    DeleteSearchConfigurationDocument,
    options,
  );
}
export type DeleteSearchConfigurationMutationHookResult = ReturnType<typeof useDeleteSearchConfigurationMutation>;
export type DeleteSearchConfigurationMutationResult = Apollo.MutationResult<Types.DeleteSearchConfigurationMutation>;
export type DeleteSearchConfigurationMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteSearchConfigurationMutation,
  Types.DeleteSearchConfigurationMutationVariables
>;
export const UpdateSearchConfigurationDocument = gql`
  mutation UpdateSearchConfiguration($params: UpdateSearchConfigInput!) {
    updateSearchConfig(input: $params) {
      ... on SearchConfiguration {
        ...SearchConfigExpanded
      }
    }
  }
  ${SearchConfigExpandedFragmentDoc}
`;
export type UpdateSearchConfigurationMutationFn = Apollo.MutationFunction<
  Types.UpdateSearchConfigurationMutation,
  Types.UpdateSearchConfigurationMutationVariables
>;

/**
 * __useUpdateSearchConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateSearchConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchConfigurationMutation, { data, loading, error }] = useUpdateSearchConfigurationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateSearchConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateSearchConfigurationMutation,
    Types.UpdateSearchConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateSearchConfigurationMutation, Types.UpdateSearchConfigurationMutationVariables>(
    UpdateSearchConfigurationDocument,
    options,
  );
}
export type UpdateSearchConfigurationMutationHookResult = ReturnType<typeof useUpdateSearchConfigurationMutation>;
export type UpdateSearchConfigurationMutationResult = Apollo.MutationResult<Types.UpdateSearchConfigurationMutation>;
export type UpdateSearchConfigurationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateSearchConfigurationMutation,
  Types.UpdateSearchConfigurationMutationVariables
>;
export const GetSearchConfigAttributesDocument = gql`
  query GetSearchConfigAttributes($params: SearchConfigAttributesInput!) {
    searchConfigAttributes(params: $params) {
      id
      label
      value
      isDynamicAttribute
      rules {
        id
        label
        value
        type
      }
    }
  }
`;

/**
 * __useGetSearchConfigAttributesQuery__
 *
 * To run a query within a React component, call `useGetSearchConfigAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchConfigAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchConfigAttributesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSearchConfigAttributesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetSearchConfigAttributesQuery,
    Types.GetSearchConfigAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSearchConfigAttributesQuery, Types.GetSearchConfigAttributesQueryVariables>(
    GetSearchConfigAttributesDocument,
    options,
  );
}
export function useGetSearchConfigAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSearchConfigAttributesQuery,
    Types.GetSearchConfigAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSearchConfigAttributesQuery, Types.GetSearchConfigAttributesQueryVariables>(
    GetSearchConfigAttributesDocument,
    options,
  );
}
export type GetSearchConfigAttributesQueryHookResult = ReturnType<typeof useGetSearchConfigAttributesQuery>;
export type GetSearchConfigAttributesLazyQueryHookResult = ReturnType<typeof useGetSearchConfigAttributesLazyQuery>;
export type GetSearchConfigAttributesQueryResult = Apollo.QueryResult<
  Types.GetSearchConfigAttributesQuery,
  Types.GetSearchConfigAttributesQueryVariables
>;
export const GetSearchConfigAttributeValuesDocument = gql`
  query GetSearchConfigAttributeValues($params: SearchConfigAttributeValuesInput!) {
    searchConfigAttributeValues(params: $params) {
      value
      label
    }
  }
`;

/**
 * __useGetSearchConfigAttributeValuesQuery__
 *
 * To run a query within a React component, call `useGetSearchConfigAttributeValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchConfigAttributeValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchConfigAttributeValuesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSearchConfigAttributeValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetSearchConfigAttributeValuesQuery,
    Types.GetSearchConfigAttributeValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSearchConfigAttributeValuesQuery, Types.GetSearchConfigAttributeValuesQueryVariables>(
    GetSearchConfigAttributeValuesDocument,
    options,
  );
}
export function useGetSearchConfigAttributeValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSearchConfigAttributeValuesQuery,
    Types.GetSearchConfigAttributeValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetSearchConfigAttributeValuesQuery,
    Types.GetSearchConfigAttributeValuesQueryVariables
  >(GetSearchConfigAttributeValuesDocument, options);
}
export type GetSearchConfigAttributeValuesQueryHookResult = ReturnType<typeof useGetSearchConfigAttributeValuesQuery>;
export type GetSearchConfigAttributeValuesLazyQueryHookResult = ReturnType<
  typeof useGetSearchConfigAttributeValuesLazyQuery
>;
export type GetSearchConfigAttributeValuesQueryResult = Apollo.QueryResult<
  Types.GetSearchConfigAttributeValuesQuery,
  Types.GetSearchConfigAttributeValuesQueryVariables
>;
export const GetSearchConfigurationDocument = gql`
  query GetSearchConfiguration($params: GetSearchConfigurationParams!) {
    searchConfiguration(params: $params) {
      ... on SearchConfiguration {
        ...SearchConfigExpanded
      }
    }
  }
  ${SearchConfigExpandedFragmentDoc}
`;

/**
 * __useGetSearchConfigurationQuery__
 *
 * To run a query within a React component, call `useGetSearchConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchConfigurationQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSearchConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetSearchConfigurationQuery, Types.GetSearchConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSearchConfigurationQuery, Types.GetSearchConfigurationQueryVariables>(
    GetSearchConfigurationDocument,
    options,
  );
}
export function useGetSearchConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSearchConfigurationQuery,
    Types.GetSearchConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSearchConfigurationQuery, Types.GetSearchConfigurationQueryVariables>(
    GetSearchConfigurationDocument,
    options,
  );
}
export type GetSearchConfigurationQueryHookResult = ReturnType<typeof useGetSearchConfigurationQuery>;
export type GetSearchConfigurationLazyQueryHookResult = ReturnType<typeof useGetSearchConfigurationLazyQuery>;
export type GetSearchConfigurationQueryResult = Apollo.QueryResult<
  Types.GetSearchConfigurationQuery,
  Types.GetSearchConfigurationQueryVariables
>;
export const GetSearchConfigurationsDocument = gql`
  query GetSearchConfigurations($filter: SearchConfigurationsFilter!, $pagination: PaginationInput!) {
    searchConfigurations(filter: $filter, pagination: $pagination) {
      data {
        id
        name
        projectId
        url
        versions {
          id
          name
          description
          createdAt
          createdBy
          updatedAt
          modifiedBy
          status
        }
      }
      total
    }
  }
`;

/**
 * __useGetSearchConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetSearchConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchConfigurationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetSearchConfigurationsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetSearchConfigurationsQuery, Types.GetSearchConfigurationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSearchConfigurationsQuery, Types.GetSearchConfigurationsQueryVariables>(
    GetSearchConfigurationsDocument,
    options,
  );
}
export function useGetSearchConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSearchConfigurationsQuery,
    Types.GetSearchConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSearchConfigurationsQuery, Types.GetSearchConfigurationsQueryVariables>(
    GetSearchConfigurationsDocument,
    options,
  );
}
export type GetSearchConfigurationsQueryHookResult = ReturnType<typeof useGetSearchConfigurationsQuery>;
export type GetSearchConfigurationsLazyQueryHookResult = ReturnType<typeof useGetSearchConfigurationsLazyQuery>;
export type GetSearchConfigurationsQueryResult = Apollo.QueryResult<
  Types.GetSearchConfigurationsQuery,
  Types.GetSearchConfigurationsQueryVariables
>;
export const CreateSearchOptimizationDocument = gql`
  mutation CreateSearchOptimization($params: CreateSearchOptimizationInput!) {
    createSearchOptimization(input: $params) {
      ... on SearchOptimization {
        ...SearchOptimizationExpanded
      }
    }
  }
  ${SearchOptimizationExpandedFragmentDoc}
`;
export type CreateSearchOptimizationMutationFn = Apollo.MutationFunction<
  Types.CreateSearchOptimizationMutation,
  Types.CreateSearchOptimizationMutationVariables
>;

/**
 * __useCreateSearchOptimizationMutation__
 *
 * To run a mutation, you first call `useCreateSearchOptimizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchOptimizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchOptimizationMutation, { data, loading, error }] = useCreateSearchOptimizationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateSearchOptimizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateSearchOptimizationMutation,
    Types.CreateSearchOptimizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateSearchOptimizationMutation, Types.CreateSearchOptimizationMutationVariables>(
    CreateSearchOptimizationDocument,
    options,
  );
}
export type CreateSearchOptimizationMutationHookResult = ReturnType<typeof useCreateSearchOptimizationMutation>;
export type CreateSearchOptimizationMutationResult = Apollo.MutationResult<Types.CreateSearchOptimizationMutation>;
export type CreateSearchOptimizationMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateSearchOptimizationMutation,
  Types.CreateSearchOptimizationMutationVariables
>;
export const DeleteSearchOptimizationDocument = gql`
  mutation DeleteSearchOptimization($input: DeleteSearchOptimizationInput!) {
    deleteSearchOptimization(input: $input) {
      ... on SuccessfulDeletionResponse {
        id
      }
    }
  }
`;
export type DeleteSearchOptimizationMutationFn = Apollo.MutationFunction<
  Types.DeleteSearchOptimizationMutation,
  Types.DeleteSearchOptimizationMutationVariables
>;

/**
 * __useDeleteSearchOptimizationMutation__
 *
 * To run a mutation, you first call `useDeleteSearchOptimizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSearchOptimizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSearchOptimizationMutation, { data, loading, error }] = useDeleteSearchOptimizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSearchOptimizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteSearchOptimizationMutation,
    Types.DeleteSearchOptimizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteSearchOptimizationMutation, Types.DeleteSearchOptimizationMutationVariables>(
    DeleteSearchOptimizationDocument,
    options,
  );
}
export type DeleteSearchOptimizationMutationHookResult = ReturnType<typeof useDeleteSearchOptimizationMutation>;
export type DeleteSearchOptimizationMutationResult = Apollo.MutationResult<Types.DeleteSearchOptimizationMutation>;
export type DeleteSearchOptimizationMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteSearchOptimizationMutation,
  Types.DeleteSearchOptimizationMutationVariables
>;
export const UpdateSearchOptimizationStateDocument = gql`
  mutation UpdateSearchOptimizationState($params: UpdateSearchOptimizationStateInput!) {
    updateSearchOptimizationState(input: $params) {
      ... on SearchOptimizationStateUpdateSuccessResponse {
        projectId
      }
    }
  }
`;
export type UpdateSearchOptimizationStateMutationFn = Apollo.MutationFunction<
  Types.UpdateSearchOptimizationStateMutation,
  Types.UpdateSearchOptimizationStateMutationVariables
>;

/**
 * __useUpdateSearchOptimizationStateMutation__
 *
 * To run a mutation, you first call `useUpdateSearchOptimizationStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchOptimizationStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchOptimizationStateMutation, { data, loading, error }] = useUpdateSearchOptimizationStateMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateSearchOptimizationStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateSearchOptimizationStateMutation,
    Types.UpdateSearchOptimizationStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateSearchOptimizationStateMutation,
    Types.UpdateSearchOptimizationStateMutationVariables
  >(UpdateSearchOptimizationStateDocument, options);
}
export type UpdateSearchOptimizationStateMutationHookResult = ReturnType<
  typeof useUpdateSearchOptimizationStateMutation
>;
export type UpdateSearchOptimizationStateMutationResult =
  Apollo.MutationResult<Types.UpdateSearchOptimizationStateMutation>;
export type UpdateSearchOptimizationStateMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateSearchOptimizationStateMutation,
  Types.UpdateSearchOptimizationStateMutationVariables
>;
export const UpdateSearchOptimizationDocument = gql`
  mutation UpdateSearchOptimization($params: UpdateSearchOptimizationInput!) {
    updateSearchOptimization(input: $params) {
      ... on SearchOptimization {
        ...SearchOptimizationExpanded
      }
    }
  }
  ${SearchOptimizationExpandedFragmentDoc}
`;
export type UpdateSearchOptimizationMutationFn = Apollo.MutationFunction<
  Types.UpdateSearchOptimizationMutation,
  Types.UpdateSearchOptimizationMutationVariables
>;

/**
 * __useUpdateSearchOptimizationMutation__
 *
 * To run a mutation, you first call `useUpdateSearchOptimizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchOptimizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchOptimizationMutation, { data, loading, error }] = useUpdateSearchOptimizationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateSearchOptimizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateSearchOptimizationMutation,
    Types.UpdateSearchOptimizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateSearchOptimizationMutation, Types.UpdateSearchOptimizationMutationVariables>(
    UpdateSearchOptimizationDocument,
    options,
  );
}
export type UpdateSearchOptimizationMutationHookResult = ReturnType<typeof useUpdateSearchOptimizationMutation>;
export type UpdateSearchOptimizationMutationResult = Apollo.MutationResult<Types.UpdateSearchOptimizationMutation>;
export type UpdateSearchOptimizationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateSearchOptimizationMutation,
  Types.UpdateSearchOptimizationMutationVariables
>;
export const GetSearchOptimizationDocument = gql`
  query GetSearchOptimization($params: GetSearchOptimizationParams!) {
    searchOptimization(params: $params) {
      ... on SearchOptimization {
        ...SearchOptimizationExpanded
      }
    }
  }
  ${SearchOptimizationExpandedFragmentDoc}
`;

/**
 * __useGetSearchOptimizationQuery__
 *
 * To run a query within a React component, call `useGetSearchOptimizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchOptimizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchOptimizationQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSearchOptimizationQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetSearchOptimizationQuery, Types.GetSearchOptimizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSearchOptimizationQuery, Types.GetSearchOptimizationQueryVariables>(
    GetSearchOptimizationDocument,
    options,
  );
}
export function useGetSearchOptimizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSearchOptimizationQuery,
    Types.GetSearchOptimizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSearchOptimizationQuery, Types.GetSearchOptimizationQueryVariables>(
    GetSearchOptimizationDocument,
    options,
  );
}
export type GetSearchOptimizationQueryHookResult = ReturnType<typeof useGetSearchOptimizationQuery>;
export type GetSearchOptimizationLazyQueryHookResult = ReturnType<typeof useGetSearchOptimizationLazyQuery>;
export type GetSearchOptimizationQueryResult = Apollo.QueryResult<
  Types.GetSearchOptimizationQuery,
  Types.GetSearchOptimizationQueryVariables
>;
export const GetSearchOptimizationsDocument = gql`
  query GetSearchOptimizations($filter: SearchOptimizationsFilter!, $sort: SortInput!, $pagination: PaginationInput!) {
    searchOptimizations(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        ...SearchOptimizationExpanded
      }
      total
    }
  }
  ${SearchOptimizationExpandedFragmentDoc}
`;

/**
 * __useGetSearchOptimizationsQuery__
 *
 * To run a query within a React component, call `useGetSearchOptimizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchOptimizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchOptimizationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetSearchOptimizationsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetSearchOptimizationsQuery, Types.GetSearchOptimizationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSearchOptimizationsQuery, Types.GetSearchOptimizationsQueryVariables>(
    GetSearchOptimizationsDocument,
    options,
  );
}
export function useGetSearchOptimizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSearchOptimizationsQuery,
    Types.GetSearchOptimizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSearchOptimizationsQuery, Types.GetSearchOptimizationsQueryVariables>(
    GetSearchOptimizationsDocument,
    options,
  );
}
export type GetSearchOptimizationsQueryHookResult = ReturnType<typeof useGetSearchOptimizationsQuery>;
export type GetSearchOptimizationsLazyQueryHookResult = ReturnType<typeof useGetSearchOptimizationsLazyQuery>;
export type GetSearchOptimizationsQueryResult = Apollo.QueryResult<
  Types.GetSearchOptimizationsQuery,
  Types.GetSearchOptimizationsQueryVariables
>;
export const CreateSynonymsSetDocument = gql`
  mutation CreateSynonymsSet($input: CreateSynonymsSetInput!) {
    createSynonymsSet(input: $input) {
      ... on SuccessfulCreateSynonymsSetResponse {
        synonymsSet {
          id
          synonyms
        }
      }
      ... on InvalidArgumentResponse {
        errorMessage
      }
    }
  }
`;
export type CreateSynonymsSetMutationFn = Apollo.MutationFunction<
  Types.CreateSynonymsSetMutation,
  Types.CreateSynonymsSetMutationVariables
>;

/**
 * __useCreateSynonymsSetMutation__
 *
 * To run a mutation, you first call `useCreateSynonymsSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSynonymsSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSynonymsSetMutation, { data, loading, error }] = useCreateSynonymsSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSynonymsSetMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateSynonymsSetMutation, Types.CreateSynonymsSetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateSynonymsSetMutation, Types.CreateSynonymsSetMutationVariables>(
    CreateSynonymsSetDocument,
    options,
  );
}
export type CreateSynonymsSetMutationHookResult = ReturnType<typeof useCreateSynonymsSetMutation>;
export type CreateSynonymsSetMutationResult = Apollo.MutationResult<Types.CreateSynonymsSetMutation>;
export type CreateSynonymsSetMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateSynonymsSetMutation,
  Types.CreateSynonymsSetMutationVariables
>;
export const DeleteSynonymsSetDocument = gql`
  mutation DeleteSynonymsSet($input: DeleteSynonymsSetInput!) {
    deleteSynonymsSet(input: $input) {
      id
    }
  }
`;
export type DeleteSynonymsSetMutationFn = Apollo.MutationFunction<
  Types.DeleteSynonymsSetMutation,
  Types.DeleteSynonymsSetMutationVariables
>;

/**
 * __useDeleteSynonymsSetMutation__
 *
 * To run a mutation, you first call `useDeleteSynonymsSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSynonymsSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSynonymsSetMutation, { data, loading, error }] = useDeleteSynonymsSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSynonymsSetMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteSynonymsSetMutation, Types.DeleteSynonymsSetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteSynonymsSetMutation, Types.DeleteSynonymsSetMutationVariables>(
    DeleteSynonymsSetDocument,
    options,
  );
}
export type DeleteSynonymsSetMutationHookResult = ReturnType<typeof useDeleteSynonymsSetMutation>;
export type DeleteSynonymsSetMutationResult = Apollo.MutationResult<Types.DeleteSynonymsSetMutation>;
export type DeleteSynonymsSetMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteSynonymsSetMutation,
  Types.DeleteSynonymsSetMutationVariables
>;
export const UpdateSynonymsSetDocument = gql`
  mutation UpdateSynonymsSet($id: ID!, $input: UpdateSynonymsSetInput!) {
    updateSynonymsSet(id: $id, input: $input) {
      ... on SuccessfulUpdateSynonymsSetResponse {
        synonymsSet {
          id
          synonyms
        }
      }
      ... on InvalidArgumentResponse {
        errorMessage
      }
    }
  }
`;
export type UpdateSynonymsSetMutationFn = Apollo.MutationFunction<
  Types.UpdateSynonymsSetMutation,
  Types.UpdateSynonymsSetMutationVariables
>;

/**
 * __useUpdateSynonymsSetMutation__
 *
 * To run a mutation, you first call `useUpdateSynonymsSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSynonymsSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSynonymsSetMutation, { data, loading, error }] = useUpdateSynonymsSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSynonymsSetMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateSynonymsSetMutation, Types.UpdateSynonymsSetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateSynonymsSetMutation, Types.UpdateSynonymsSetMutationVariables>(
    UpdateSynonymsSetDocument,
    options,
  );
}
export type UpdateSynonymsSetMutationHookResult = ReturnType<typeof useUpdateSynonymsSetMutation>;
export type UpdateSynonymsSetMutationResult = Apollo.MutationResult<Types.UpdateSynonymsSetMutation>;
export type UpdateSynonymsSetMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateSynonymsSetMutation,
  Types.UpdateSynonymsSetMutationVariables
>;
export const GetSynonymsDocument = gql`
  query GetSynonyms($params: GetSynonymsSetsInput!) {
    synonymsSets(params: $params) {
      id
      synonyms
    }
  }
`;

/**
 * __useGetSynonymsQuery__
 *
 * To run a query within a React component, call `useGetSynonymsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSynonymsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSynonymsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSynonymsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetSynonymsQuery, Types.GetSynonymsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetSynonymsQuery, Types.GetSynonymsQueryVariables>(GetSynonymsDocument, options);
}
export function useGetSynonymsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSynonymsQuery, Types.GetSynonymsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetSynonymsQuery, Types.GetSynonymsQueryVariables>(GetSynonymsDocument, options);
}
export type GetSynonymsQueryHookResult = ReturnType<typeof useGetSynonymsQuery>;
export type GetSynonymsLazyQueryHookResult = ReturnType<typeof useGetSynonymsLazyQuery>;
export type GetSynonymsQueryResult = Apollo.QueryResult<Types.GetSynonymsQuery, Types.GetSynonymsQueryVariables>;
export const CreateUnidirectionalSynonymsSetDocument = gql`
  mutation CreateUnidirectionalSynonymsSet($input: CreateUnidirectionalSynonymsSetInput!) {
    createUnidirectionalSynonymsSet(input: $input) {
      ... on SuccessfulCreateUnidirectionalSynonymsSetResponse {
        synonymsSet {
          id
          inputs
          outputs
        }
      }
      ... on InvalidUnidirectionalArgumentResponse {
        errorMessage
      }
    }
  }
`;
export type CreateUnidirectionalSynonymsSetMutationFn = Apollo.MutationFunction<
  Types.CreateUnidirectionalSynonymsSetMutation,
  Types.CreateUnidirectionalSynonymsSetMutationVariables
>;

/**
 * __useCreateUnidirectionalSynonymsSetMutation__
 *
 * To run a mutation, you first call `useCreateUnidirectionalSynonymsSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnidirectionalSynonymsSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnidirectionalSynonymsSetMutation, { data, loading, error }] = useCreateUnidirectionalSynonymsSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUnidirectionalSynonymsSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateUnidirectionalSynonymsSetMutation,
    Types.CreateUnidirectionalSynonymsSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateUnidirectionalSynonymsSetMutation,
    Types.CreateUnidirectionalSynonymsSetMutationVariables
  >(CreateUnidirectionalSynonymsSetDocument, options);
}
export type CreateUnidirectionalSynonymsSetMutationHookResult = ReturnType<
  typeof useCreateUnidirectionalSynonymsSetMutation
>;
export type CreateUnidirectionalSynonymsSetMutationResult =
  Apollo.MutationResult<Types.CreateUnidirectionalSynonymsSetMutation>;
export type CreateUnidirectionalSynonymsSetMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateUnidirectionalSynonymsSetMutation,
  Types.CreateUnidirectionalSynonymsSetMutationVariables
>;
export const DeleteUnidirectionalSynonymsSetDocument = gql`
  mutation DeleteUnidirectionalSynonymsSet($input: DeleteUnidirectionalSynonymsSetInput!) {
    deleteUnidirectionalSynonymsSet(input: $input) {
      id
    }
  }
`;
export type DeleteUnidirectionalSynonymsSetMutationFn = Apollo.MutationFunction<
  Types.DeleteUnidirectionalSynonymsSetMutation,
  Types.DeleteUnidirectionalSynonymsSetMutationVariables
>;

/**
 * __useDeleteUnidirectionalSynonymsSetMutation__
 *
 * To run a mutation, you first call `useDeleteUnidirectionalSynonymsSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnidirectionalSynonymsSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnidirectionalSynonymsSetMutation, { data, loading, error }] = useDeleteUnidirectionalSynonymsSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUnidirectionalSynonymsSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteUnidirectionalSynonymsSetMutation,
    Types.DeleteUnidirectionalSynonymsSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteUnidirectionalSynonymsSetMutation,
    Types.DeleteUnidirectionalSynonymsSetMutationVariables
  >(DeleteUnidirectionalSynonymsSetDocument, options);
}
export type DeleteUnidirectionalSynonymsSetMutationHookResult = ReturnType<
  typeof useDeleteUnidirectionalSynonymsSetMutation
>;
export type DeleteUnidirectionalSynonymsSetMutationResult =
  Apollo.MutationResult<Types.DeleteUnidirectionalSynonymsSetMutation>;
export type DeleteUnidirectionalSynonymsSetMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteUnidirectionalSynonymsSetMutation,
  Types.DeleteUnidirectionalSynonymsSetMutationVariables
>;
export const UpdateUnidirectionalSynonymsSetDocument = gql`
  mutation UpdateUnidirectionalSynonymsSet($id: ID!, $input: UpdateUnidirectionalSynonymsSetInput!) {
    updateUnidirectionalSynonymsSet(id: $id, input: $input) {
      ... on SuccessfulUpdateUnidirectionalSynonymsSetResponse {
        synonymsSet {
          id
          inputs
          outputs
        }
      }
      ... on InvalidUnidirectionalArgumentResponse {
        errorMessage
      }
    }
  }
`;
export type UpdateUnidirectionalSynonymsSetMutationFn = Apollo.MutationFunction<
  Types.UpdateUnidirectionalSynonymsSetMutation,
  Types.UpdateUnidirectionalSynonymsSetMutationVariables
>;

/**
 * __useUpdateUnidirectionalSynonymsSetMutation__
 *
 * To run a mutation, you first call `useUpdateUnidirectionalSynonymsSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnidirectionalSynonymsSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnidirectionalSynonymsSetMutation, { data, loading, error }] = useUpdateUnidirectionalSynonymsSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUnidirectionalSynonymsSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateUnidirectionalSynonymsSetMutation,
    Types.UpdateUnidirectionalSynonymsSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateUnidirectionalSynonymsSetMutation,
    Types.UpdateUnidirectionalSynonymsSetMutationVariables
  >(UpdateUnidirectionalSynonymsSetDocument, options);
}
export type UpdateUnidirectionalSynonymsSetMutationHookResult = ReturnType<
  typeof useUpdateUnidirectionalSynonymsSetMutation
>;
export type UpdateUnidirectionalSynonymsSetMutationResult =
  Apollo.MutationResult<Types.UpdateUnidirectionalSynonymsSetMutation>;
export type UpdateUnidirectionalSynonymsSetMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateUnidirectionalSynonymsSetMutation,
  Types.UpdateUnidirectionalSynonymsSetMutationVariables
>;
export const GetUnidirectionalSynonymsDocument = gql`
  query GetUnidirectionalSynonyms($params: GetUnidirectionalSynonymsSetsInput!) {
    unidirectionalSynonymsSets(params: $params) {
      id
      inputs
      outputs
    }
  }
`;

/**
 * __useGetUnidirectionalSynonymsQuery__
 *
 * To run a query within a React component, call `useGetUnidirectionalSynonymsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnidirectionalSynonymsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnidirectionalSynonymsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetUnidirectionalSynonymsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetUnidirectionalSynonymsQuery,
    Types.GetUnidirectionalSynonymsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetUnidirectionalSynonymsQuery, Types.GetUnidirectionalSynonymsQueryVariables>(
    GetUnidirectionalSynonymsDocument,
    options,
  );
}
export function useGetUnidirectionalSynonymsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetUnidirectionalSynonymsQuery,
    Types.GetUnidirectionalSynonymsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetUnidirectionalSynonymsQuery, Types.GetUnidirectionalSynonymsQueryVariables>(
    GetUnidirectionalSynonymsDocument,
    options,
  );
}
export type GetUnidirectionalSynonymsQueryHookResult = ReturnType<typeof useGetUnidirectionalSynonymsQuery>;
export type GetUnidirectionalSynonymsLazyQueryHookResult = ReturnType<typeof useGetUnidirectionalSynonymsLazyQuery>;
export type GetUnidirectionalSynonymsQueryResult = Apollo.QueryResult<
  Types.GetUnidirectionalSynonymsQuery,
  Types.GetUnidirectionalSynonymsQueryVariables
>;
